.footer-container {
  // position: absolute;
  // bottom: 0;
  background-color: #f3f3f3;
  width: 100%;
  max-width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  .content {
    padding: 8px 0;
    display: grid;
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    color: #535252;
    vertical-align: middle;

    @media all and (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
    .info-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .address-info {
        text-align: justify;
        width: calc(100% - 60px);
        margin: auto;
      }
      @media all and (max-width: 1024px) {
        .address-info {
          width: 100%;
          text-align: center;
        }
      }
      .small-logo-container {
        display: flex;
        margin: auto;
        flex-wrap: wrap;
        justify-content: center;

        .small-logo {
          max-width: 50px;
          max-height: 40px;
          width: auto;
          height: auto;
          margin: 0 4px;
        }
      }
    }
    .contact-container {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      margin-left: auto;
      justify-content: flex-end;
      width: fit-content;
      margin-bottom: 16px;
      .contact {
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        a {
          align-self: center;
        }
        .contact-label {
          align-self: flex-end;
          font-weight: 700;
          color: #5ab2a5;
        }

        @media all and (max-width: 1024px) {
          .contact-label {
            align-self: center;
          }
        }

        a {
          font-size: 13px;
          font-family: 'Montserrat', sans-serif;
          -webkit-font-smoothing: antialiased;
          color: #77787b;
          text-decoration: unset;
          transition: all 0.3s;
          &:hover {
            color: #5ab2a5;
            transition: all 0.3s;
          }
          &:focus {
            color: #3addc5;
            transition: all 0.3s;
          }
          // &:visited {
          //   color: #a7aec2;
          // }
          &:active {
            color: #77787b;
            text-decoration: none;
            transition: all 0.3s;
          }
        }
      }
    }
    @media all and (max-width: 1024px) {
      .contact-container {
        justify-content: center;
        margin: 10px auto;
      }
    }

    .social {
      display: flex;
      justify-content: flex-end;
      margin: auto 8px 0 auto;
      .social-link {
        width: 35px;
        cursor: pointer;
        height: auto;
        margin-left: 5px;
        vertical-align: middle;

        &:hover {
          color: #62c4b5;
          transition: all 0.3s;
          .st0 {
            fill: #b9d35e;
            transition: all 0.3s;
          }
          .st1 {
            stroke: #b9d35e;
            transition: all 0.3s;
          }
        }
      }
    }
    @media all and (max-width: 1024px) {
      .social {
        justify-content: center;
        margin: auto;
      }
    }
  }
  .legal {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 20px;
    padding: 8px;
    &::after {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #8b8b8b;
    }
    .copyright-info {
      display: flex;
      user-select: none;
      flex-wrap: wrap;
      flex: 0 0 50%;
      justify-content: flex-start;
      padding: 0 8px;
      //   max-width: 50%;
      font-size: 12px;
      color: #77787b;
    }
    @media screen and (max-width: 1024px) {
      .copyright-info {
        text-align: center;
      }
    }
    .guides-and-privacy {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      a {
        padding: 0 8px;
        font-size: 12px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        color: #77787b;
        text-decoration: unset;
        transition: all 0.3s;
        &:hover {
          color: #5ab2a5;
          transition: all 0.3s;
        }
        &:focus {
          color: #3addc5;
          transition: all 0.3s;
        }
        // &:visited {
        //   color: #a7aec2;
        // }
        &:active {
          color: #77787b;
          text-decoration: none;
          transition: all 0.3s;
        }
      }
      .legal-link {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        font-weight: normal;
        font-size: 12px;
        color: #77787b;
      }
      @media all and (max-width: 1024px) {
        .legal-link {
          min-width: 100%;
        }
      }
    }
    @media all and (max-width: 1024px) {
      .guides-and-privacy {
        justify-content: center;
      }
    }
  }
}
