.main-progress-container {
  width: 100%;
  padding: 0 4px 0 4px;
  height: 18px;
  margin: 0 auto;
  padding: 5px;
  background-color: #5ab2a5;
  border-radius: 15px;

  .progress-bar-container {
    margin: auto;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 9fr 1fr;

    .progress-percent {
      display: inline-block;
      margin: auto;
      vertical-align: top;
      color: #fff;
      font-size: 16px;
      transition: all 0.5s ease-in-out;
    }
    .progress-graphic {
      display: inline-block;
      width: 100%;
      height: 10px;
      padding: 3px;
      border: 1px solid #fff;
      border-radius: 16px;
      transition: width 0.5s ease-in-out;
      .progress-empty {
        height: 10px;
        background-color: #fff;
        border-radius: 10px;
        transition: width 0.5s ease-in-out;
      }
    }
  }
}
