.recruiter-link {
  font-size: calc(10px + 1vmin);
  text-decoration: none;
  color: #38b6ab;
  transition: all 0.5s linear;
  &:hover {
    transition: all 0.5s linear;
    color: #000000;
  }
}
