.terms-and-conditions-wrapper {
    width: 100%;
    min-height: 65vh;
    .th-difference-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-family: Montserrat;
        font-size: 34px;
        font-weight: bold;
        line-height: 0.62;
        letter-spacing: -1px;
        text-align: center;
        color: #5ab2a5;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 700px) {
        h1 {
          line-height: 1;
        }
      }
  
      .th-differences {
        width: 70%;
        padding-inline-start: 0;
        margin: auto;
        list-style-type: none;
        .th-difference-item {
          margin: 18px auto;
          display: grid;
          grid-template-columns: 50px 6fr;
          grid-column-gap: 20px;
          align-content: center;
          .th-difference-item-bullet {
            display: flex;
            justify-content: center;
            color: white;
            margin: 0 auto;
            text-align: center;
            box-sizing: border-box;
            padding: auto;
            align-items: center;
            .th-difference-number {
              margin: auto;
              font-size: 35px;
            }
          }
          @media screen and (max-width: 700px) {
            .th-difference-item-bullet {
              width: 30px;
              height: 30px;
              .th-difference-number {
                font-size: 20px;
              }
            }
          }
          .th-difference-point {
            display: flex;
            flex-direction: column;
            .th-difference-head {
              margin-top: 18px;
              font-size: 1.6rem;
              font-weight: 600;
              text-align: left;
              color: #313131;
            }
            .th-difference-sub {
              font-size: 1rem;
              text-align: left;
              color: #9b9a9a;
            }
          }
        }
        @media screen and (max-width: 700px) {
          .th-difference-item {
            grid-column-gap: 10px;
          }
        }
      }
      @media screen and (max-width: 700px) {
        .th-differences {
          width: 90%;
        }
      }
    }
    .b2b-container {
      width: 75%;
      margin: 20px auto;
  
      .hero {
        box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 1);
  
        &::before {
          background-image: url('../../../assets/b2b-hero_small.jpg');
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: 50%;
          background-size: cover;
          filter: blur(5px);
          z-index: -1;
          margin: 0;
          padding: 0;
        }
        background-image: url('../../../assets/b2b-hero.jpg');
        background-size: cover;
        background-position: 50%;
        height: 450px;
        position: relative;
        .hero-elements {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          display: flex;
          flex-direction: column;
          .hero-headline {
            text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
            font-weight: 500;
            color: white;
            text-align: center;
            font-size: 30px;
            letter-spacing: 2px;
            b {
              letter-spacing: 1px;
            }
          }
          @media screen and (max-width: 700px) {
            .hero-headline {
              font-size: 18px;
            }
          }
        }
  
        .register-button {
          box-sizing: border-box;
          padding: 10px 2rem;
          background-color: #5ab2a5;
          color: white;
          font-weight: 600;
          font-size: 20px;
          text-align: center;
          margin: 10px auto;
          // margin: 10px;
          // position: absolute;
          // left: 50%;
          // bottom: 10%;
          // transform: translate(-50%, 0);
          &:hover {
            background-color: #b9d35e;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
            transition: all 0.3s ease 0s;
            cursor: pointer;
          }
        }
      }
      @media screen and (max-width: 500px) {
        .hero {
          top: 0;
          .register-button {
            font-size: calc(1.1vh + 0.3rem);
          }
        }
      }
    }
    .homepage-b2b {
      display: flex;
      justify-content: center;
      margin: 20px auto;
      font-weight: 600;
      font-size: 20px;
      width: 60%;
      text-align: center;
    }
    .homepage-subscript {
      display: flex;
      justify-content: center;
      margin: 20px auto;
      width: 60%;
      font-size: 16px;
      text-align: center;
      color: #9b9a9a;
      font-weight: 600;
    }
    @media screen and (max-width: 500px) {
      .homepage-subscript {
        width: 80%;
      }
    }
  }
  