.questionnaire-container {
  .hero-image {
    position: relative;
    width: 75%;
    margin: 20px auto;
    height: 270px;
    margin-bottom: 15px;
    background-image: url('../../../assets/bckRegistration.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 1);
    .hero-elements {
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      display: flex;
      flex-direction: column;
      .hero-headline {
        font-weight: 700;
        color: white;
        font-size: 30px;
        text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
        text-align: center;
        letter-spacing: 3px;
        b {
          letter-spacing: 1px;
        }
      }
      @media screen and (max-width: 700px) {
        .hero-headline {
          font-size: 18px;
        }
      }
    }
  }
  @media all and (max-width: 1024px) {
    .hero-image {
      height: 200px;
    }
  }

  b {
    font-weight: 600;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    width: calc((100% / 6) * 4);
    justify-content: center;
    margin: auto;

    .progress-bar-questionnaire-container {
      width: 95%;
      margin: auto;
    }

    .th-form-actions {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-bottom: 2rem;
    }
    .content-column {
      display: flex;
      flex-direction: column;
      margin: auto;
      width: 80%;
      position: static;
      opacity: 1;
      transition: opacity 0.5s linear;
    }

    .content-column-hidden {
      @extend .content-column;
      opacity: 0;
      position: absolute;
      height: 0;
      bottom: 0;
      overflow: hidden;
      transition: opacity 0.5s linear;
    }

    @media screen and (max-width: 1024px) {
      .content-column {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .main-content {
      width: 80%;
    }
  }
  @media (max-width: 768px) {
    .main-content {
      width: calc(100% - 60px);
      margin: 0 30px;
    }
  }
}

.ad-hoc-label {
  font-size: 12px;
  font-weight: 600;
  color: #313131;
  box-sizing: border-box;
}
.divider {
  width: 100%;
  position: relative;
  padding: 30px 0;
  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 60vw;
    height: 1px;
    content: '';
    border-bottom: 1px solid grey;
    overflow: visible;
  }
}
@media (max-width: 768px) {
  .divider {
    &::after {
      width: 80vw;
    }
  }
}
a {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #5ab2a5;
  text-decoration: unset;
  transition: all 0.3s;
  &:hover {
    color: #b9d35e;
    transition: all 0.3s;
  }
  &:focus {
    color: #3addc5;
    transition: all 0.3s;
  }
  // &:visited {
  //   color: #a7aec2;
  // }
  &:active {
    color: #77787b;
    text-decoration: none;
    transition: all 0.3s;
  }
}
