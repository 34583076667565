.datepicker-grid-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  max-width: 600px;
}
@media screen and (max-width: 700px) {
  .datepicker-grid-holder {
    max-width: 100%;
  }
}
