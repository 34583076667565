.header-wrapper {
  min-height: 150px;
}
@media screen and (max-width: 700px) {
  .header-wrapper {
    min-height: 50px;
    height: 50px;
  }
}

.header-container {
  z-index: 20;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 5% 0 5%;
  display: flex;
  background-color: white;
  flex-direction: column;

  .user-control {
    position: absolute;
    right: 16px;
    font-size: calc(10px + 0.2vw);
  }
  // transition: all 0.5s ease;
  .central-logo {
    position: relative;
    justify-content: center;
    display: flex;
    height: 150px;
    width: 150px;
    .header-logo-image {
      height: 150px;
      position: absolute;
      transition: opacity 0.5s ease;
      justify-content: center;
      align-content: center;
      display: block;
      width: auto;
    }
  }
}
@media screen and (max-width: 700px) {
  .header-container {
    display: none;
  }
}
.selected {
  color: #5ab2a5 !important;
}

.scrolled {
  padding: 10px 10% 10px 10%;
  position: fixed;
  height: 75px;
  box-shadow: 0px 0px 33px -2px rgba(0, 0, 0, 0.75);
  flex-direction: row;
}

.small--logo {
  // transition: all 0.5s ease;
  height: 150px !important;
}

.header-container-mobile {
  display: none;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  background: white;
  z-index: 20;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 8px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
  .container {
    display: inline-block;
    cursor: pointer;
  }

  .bar1,
  .bar2,
  .bar3 {
    margin: auto;
    width: 35px;
    height: 4px;
    background-color: #5ab2a5;
    margin: 6px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: rotate(-45deg) translate(-7px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
  }
}
@media screen and (max-width: 700px) {
  .header-container-mobile {
    display: flex;
  }
}

// .hamburger-enter {
//   opacity: 0.01;
// }
.hamburger-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.hamburger-leave {
  opacity: 1;
  transition: all 300ms ease-in;
}

.hamburger-leave-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}
.hamburger-appear {
  transform: translate(100%, 0);
  // opacity: 0.01;
}

.hamburger-appear-active {
  // opacity: 1;
  // transition: opacity 0.5s ease-in;
  transition: all 0.5s ease;
  transform: translate(0, 0);
}

.centered {
  align-items: center;
  justify-content: center;
}

.navbar {
  // width: 100%;
  display: flex;
  font-weight: bold;
  color: #77787b;
  box-sizing: border-box;

  flex-wrap: wrap;
  ul {
    padding-inline-start: 0px;
  }
  .nav-item {
    font-size: 15px;
    font-weight: bold;
    color: #77787b;
    a {
      font-size: calc(10px + 0.2vw);
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: antialiased;
      padding: 0 calc(1px + 1vw);
      color: #77787b;
      text-decoration: unset;
      transition: color 0.3s;
      &:hover {
        color: #5ab2a5;
        transition: color 0.3s;
      }
      &:focus {
        color: #3addc5;
        transition: color 0.3s;
      }
      // &:visited {
      //   color: #a7aec2;
      // }
      &:active {
        color: #77787b;
        text-decoration: none;
        transition: color 0.3s;
      }
    }
    display: inline-block;
    // margin: 0 16px;
    padding: 0 16px;
    box-sizing: border-box;
    border-collapse: separate;
  }
  .not-last {
    border-right-color: grey;
    border-right-style: solid;
    border-right-width: 2px;

    border-collapse: collapse;
  }
}
