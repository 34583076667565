.input-container {
  padding-bottom: 1.3rem;
  box-sizing: border-box;
  // padding-left: 5%;
  // padding-right: 5%;
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-outer-spin-button,
  input[type='time']::-webkit-inner-spin-button,
  input[type='time']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error {
    font-size: 12px;
    padding-left: 16px;
    color: #f21818;
    font-style: oblique;
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    color: #5ab2a5;
    font-style: oblique;
  }

  .input-note {
    font-size: 12px;
    font-weight: 600;
    color: #77787b;
  }

  input {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    width: 100%;
    min-height: 35px;
    margin-top: 15px;
    padding: 0 5px;
    background: #f3f3f3;
    border: 1px solid #8b8b8b;
    border-radius: 0;
    color: #77787b;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    outline: none;
    &:focus {
      background-color: #fff;
    }
  }
  label {
    font-size: 15px;
    // font-weight: bold;
    color: #313131;
  }
}

.placeholder {
  opacity: 0.3;
  box-sizing: border-box;
  width: 100%;
  min-height: 35px;
  margin-top: 15px;
  padding: 0 5px;
  background: #f3f3f3;
  border: none;
  border-radius: 0;
  color: #77787b;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  outline: none;
  background: linear-gradient(1deg, #b9d35e, #5ab2a5);
  background-size: 400% 100%;
  animation: fluidGradient 1s ease infinite;
}

@keyframes fluidGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.caps-container {
  position: absolute;
  .caps-popup {
    position: relative;
    background: rgba(90, 178, 165, 0.8);
    border: 1 px solid black;
    border-radius: 5px;
    padding: 5px;
    margin-left: 20px;
    color: whitesmoke;
    font-weight: 600;
    &:after {
      content: '\A';
      border-style: solid;
      border-width: 10px 15px 10px 0;
      border-radius: 5px;
      transform: rotate(90deg);
      border-color: transparent rgba(90, 178, 165, 0.8) transparent transparent;
      position: absolute;
      left: 10px;
      top: -16px;
    }
    &:before {
      content: ' ';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border: 0.5px solid rgb(245, 245, 245);
      border-radius: 5px;
    }
  }
}
