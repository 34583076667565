.th-button {
  padding: 15px 2rem;
  border: none;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  background-color: #5ab2a5;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    background-color: #b9d35e;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }
  &:disabled{
  background-color: #959595;
  color: rgba(255, 255, 255, 0.445);


  }
}
.inactive {
  color: #5ab2a5;
  background-color: white;
  border: 1px solid #5ab2a5;
}
