html {
  margin: 0;
  padding: 0;
  height: 100vh;
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
.App {
  text-align: center;
}
// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 40vmin;
//   pointer-events: none;
// }

.App-header {
  position: relative;
  background-repeat: no-repeat;
  // background-image: url('./assets/UnderConstruction-small.jpg');
  object-fit: fill;
  background-position-x: 50%;
  background-position-y: 30%;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.under-construction {
  font-family: 'Montserrat', sans-serif;
  // position: absolute;
  font-size: calc(10px + 1vmin);
  bottom: 5%;
  padding: 0 10vw 0 10vw;
  color: #494848;
  a {
    text-decoration: none;
    color: #38b6ab;
    transition: all 0.5s linear;
    &:hover {
      transition: all 0.5s linear;
      color: #000000;
    }
  }
}

.logo-images {
  width: 90%;
  max-width: 800px;
}

.logo-image {
  width: 60%;
  max-width: 600px;
}

.App-link {
  color: #61dafb;
}

.page-content-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 220px auto;
  grid-row-gap: 10px;
  .header {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .footer {
    grid-row-start: 2;
    grid-row-end: 3;
  }
}
@media screen and (max-width: 700px) {
  .page-content-container {
    grid-template-rows: 60px auto;
  }
}

.h2-center {
  color: #5ab2a5;
  font-size: 34px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.h3-center {
  font-weight: 400;
  font-size: 16px;
  margin: auto auto 25px auto;
  text-align: center;
  width: 100%;
}
