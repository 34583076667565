.radio-container {
  box-sizing: border-box;
  padding: 16px 0 0 0;
  padding-bottom: 0.5rem;

  .error {
    font-size: 12px;
    padding-left: 16px;
    color: #f21818;
    font-style: oblique;
  }
  .radio-note {
    font-size: 12px;
    font-weight: 600;
    color: #77787b;
  }
  .label {
    font-size: 14px;
    // font-weight: bold;
    color: #313131;
    display: block;
    margin-bottom: 1em;
  }

  label {
    font-size: 14px;
    // font-weight: bold;
    color: #313131;
  }

  .single-radio-container {
    padding-bottom: 0.5rem;
  }

  input {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid #8b8b8b;
    outline: 0;
    vertical-align: top;
    cursor: pointer;
    border-radius: 50%;
    color: #77787b;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    &:active {
      background-color: white;
    }
  }

  .checked-label {
    width: calc(100% - 55px);
    margin-top: -10px;
    // padding: 10px 4px;
    border-radius: 4px;
    font-weight: bolder;
    // background-color: #b9b7b7;
    transition: all 0.1s ease-in;
  }
}
.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-template-rows: repeat(10, auto);
}
@media screen and (max-width: 1280px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
  }
}
