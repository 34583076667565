.select-container {
  box-sizing: border-box;
  padding-bottom: 1.3rem;
  .select-note {
    font-size: 12px;
    // font-weight: 600;
    color: #313131;
    b {
      font-weight: 600;
    }
  }
  .label {
    font-size: 15px;
    color: #313131;
    display: block;
  }
  .error {
    font-size: 12px;
    padding-left: 16px;
    color: #f21818;
    font-style: oblique;
  }

  label {
    font-size: 15px;
    color: #313131;
  }

  .option-bold {
    font-weight: bold;
  }
  .option {
    font-size: 16px;
    color: #5ab2a5;
  }
  select {
    font-family: inherit;
    width: 100%;
    height: 35px;
    margin-top: 15px;
    padding: 0 5px;
    background: #f3f3f3;
    border: 1px solid #8b8b8b;
    border-radius: 0;
    color: #77787b;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    outline: none;
  }
}
select:focus {
  background-color: white;
}

.placeholder {
  opacity: 0.3;
  box-sizing: border-box;
  width: 100%;
  min-height: 35px;
  margin-top: 15px;
  padding: 0 5px;
  background: #f3f3f3;
  border: none;
  border-radius: 0;
  color: #77787b;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  outline: none;
  background: linear-gradient(1deg, #b9d35e, #5ab2a5);
  background-size: 400% 100%;
  animation: fluidGradient 1s ease infinite;
}

@keyframes fluidGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 5%;
  }
  100% {
    background-position: 0% 50%;
  }
}
