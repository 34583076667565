.dialog-wrapper {
  transition: all 0.5s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 21;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.47);
  display: flex;
  .dialog-container {
    display: flex;
    z-index: 22;
    position: relative;
    margin: auto;
    // min-height: 300px;
    // min-width: 30%;
    background: white;
    padding-bottom: 60px;
  }

  .dialog-container-bottom {
    display: flex;
    z-index: 22;
    position: relative;
    margin: auto auto 0 auto;
    // min-height: 300px;
    // min-width: 30%;
    background: white;
    padding-bottom: 60px;
  }

      .dialog-container-content {
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
      .dialog-container-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px;
        width: 100%;
        height: 60px;
        background-color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: row-reverse;
      }
}

.dialog-appear {
  opacity: 0.01;
}

.dialog-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
