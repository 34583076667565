.login-wrapper {
  min-height: 60vh;
  .login-container {
    width: 75%;
    margin: 20px auto;
    position: relative;
    .hero {
      border-radius: 8px;
      // box-shadow: inset 0px 0px 30px 15px white;
      &::before {
        box-shadow: inset 0px 0px 30px 15px white;

        background-image: url('../../../assets/login-backdrop_small.jpg');
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90%;
        background-position: 50%;
        background-size: cover;
        filter: blur(5px);
        z-index: -1;
        margin: 0;
        padding: 0;
      }
      background-image: url('../../../assets/login-backdrop.jpg');
      background-size: cover;
      background-position: 50%;
      min-height: 430px;
      position: relative;
      .hero-elements {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        .hero-headline {
          text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
          font-weight: 500;
          color: white;
          text-align: center;
          font-size: 30px;
          letter-spacing: 2px;
          b {
            letter-spacing: 1px;
          }
        }
        @media screen and (max-width: 700px) {
          .hero-headline {
            font-size: 18px;
          }
        }
      }
      @media screen and (max-width: 700px) {
        .hero-elements {
          width: 80%;
        }
      }

      .register-button {
        background-color: red;
        box-sizing: border-box;
        padding: 10px 2rem;
        background-color: #5ab2a5;
        color: white;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        margin: 10px auto;
        // margin: 10px;
        // position: absolute;
        // left: 50%;
        // bottom: 10%;
        // transform: translate(-50%, 0);
        &:hover {
          background-color: #b9d35e;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
          transition: all 0.3s ease 0s;
          cursor: pointer;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .hero {
        top: 0;
        width: 100%;
        .register-button {
          font-size: calc(1.1vh + 0.3rem);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .login-container {
      width: 95%;
    }
  }

  ul {
    padding-inline-start: 0px;
    display: flex;
    justify-content: center;
  }
  .nav-item {
    font-size: 15px;
    font-weight: bold;
    color: #77787b;
    cursor: pointer;
    span {
      font-size: calc(10px + 0.3vw);
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: antialiased;
      padding: 0 calc(1px + 1vw);
      color: #5ab2a5;
      text-decoration: unset;
      transition: color 0.3s;
      &:hover {
        color: #b9d35e;
        transition: color 0.3s;
      }
      &:focus {
        color: #3addc5;
        transition: color 0.3s;
      }
      // &:visited {
      //   color: #a7aec2;
      // }
      &:active {
        color: #77787b;
        text-decoration: none;
        transition: color 0.3s;
      }
    }
    display: inline-block;
    // margin: 0 16px;
    padding: 0 16px;
    box-sizing: border-box;
    border-collapse: separate;
  }
  .not-last {
    border-right-color: grey;
    border-right-style: solid;
    border-right-width: 2px;

    border-collapse: collapse;
  }
}
