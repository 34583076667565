.reset-password-paper {
  padding-top: 50px;
  width: 30%;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .reset-password-paper {
    width: 80%;
  }
}

.save-actionbar {
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  height: 50px;
}
