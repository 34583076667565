.profile-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .activation-heading {
    text-align: center;
    color: red;
    font-size: 16px;
  }

  .section-block {
    padding: 2rem;
    border-radius: 8px;
    .section-heading {
      text-align: center;
      color: #5ab2a5;
      font-size: 30px;
      text-transform: uppercase;
    }
    p {
      text-align: center;
    }
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .inputs-container {
      display: grid;
      margin: 16px auto;
      width: 100%;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 1280px) {
      .inputs-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .save-actionbar {
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    height: 50px;
  }
  .hidden {
    opacity: 0;
    transition: all 0.3s ease-in-out;

    height: 0px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  position: relative;
  z-index: 0;
  // width: 100%;
  // height: 100%;
  border-radius: 8px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#5ab2a5, #5ab2a5), linear-gradient(#b9d35e, #b9d35e),
      linear-gradient(#5ab2a5, #5ab2a5), linear-gradient(#b9d35e, #b9d35e);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

.first-column-input {
  grid-column: 1/1;
}
.second-column-input {
  grid-column: 2/2;
}
