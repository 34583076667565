.activate-account-paper {
  width: 30%;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .activate-account-paper {
    width: 80%;
  }
}
.activate-account-wrapper {
  .register-button {
    box-sizing: border-box;
    padding: 10px 2rem;
    background-color: #5ab2a5;
    color: white;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: 10px auto;
    // margin: 10px;
    // position: absolute;
    // left: 50%;
    // bottom: 10%;
    // transform: translate(-50%, 0);
    &:hover {
      background-color: #b9d35e;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
      color: white;
      transition: all 0.3s ease 0s;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 500px) {
    .hero {
      top: 0;
      .register-button {
        font-size: calc(1.1vh + 0.3rem);
      }
    }
  }
}
